.portfolio-background-gradient {
  background-size: 100% 100%;
  background-position: 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px, 0px 0px;
  background-image: radial-gradient(
      40% 60% at 38% 50%,
      #595c5fe0 1%,
      #595c5fe0 1%,
      #073aff00 100%
    ),
    radial-gradient(
      135% 200% at 12% 19%,
      #030100e6 1%,
      #030100e6 21%,
      #ff000000 86%
    ),
    radial-gradient(
      150% 91% at 96% 31%,
      #ffffffff 0%,
      #ffffffff 17%,
      #ffffffff 51%,
      #ff000000 99%
    ),
    radial-gradient(130% 80% at 105% 73%, #030100e6 0%, #595c5fff 98%),
    radial-gradient(75% 75% at 66% 63%, #ffffff 1%, #ffffffff 59%, #ff00 100%);
}

